import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { connectRouter } from "connected-react-router";
import user from "./user/reducers";
import settings from "./user/reducers";
import app from './appReducer.js'
import { createBrowserHistory } from "history";

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['app']
}

const history = createBrowserHistory()
const rootReducer = combineReducers({
  router: connectRouter(history),
  user,
  settings,
  app,
});

export default persistReducer(persistConfig, rootReducer)
