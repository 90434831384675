import React, { Fragment, lazy, Suspense } from "react";
import { ConnectedRouter } from "connected-react-router";
import ScrollToTop from "./App/Components/MyComponents/ScrollToTop.js";
import { Redirect, Route, withRouter, Switch } from "react-router-dom";
// import AppVisible from "./App/Components/MyComponents/AppVisible";
// import ScrollToTop from "./global/ScrollToTop";
import Header from "./App/Components/Header/index";
import App from "./App/index.js";
const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./App/Screens/Onboarding/index")),
  },
  {
    path: "/home",
    exact: true,
    Component: lazy(() => import("./App/Screens/HomeScreen")),
  },
  {
    path: "/login",
    exact: true,
    Component: lazy(() => import("./App/Screens/Login/index")),
  },
  {
    path: "/signup",
    exact: true,
    Component: lazy(() => import("./App/Screens/SignUp/index")),
  },
  {
    path: "/interests",
    exact: true,
    Component: lazy(() => import("./App/Screens/Interests/index")),
  },
  {
    path: "/forgotPassword",
    exact: true,
    Component: lazy(() => import("./App/Screens/ForgotPassword/index")),
  },
  {
    path: "/gallery",
    exact: true,
    Component: lazy(() => import("./App/Screens/Gallery/index")),
  },
  {
    path: "/explore",
    exact: true,
    Component: lazy(() => import("./App/Screens/Village/index")),
  },
  {
    path: "/searchonmap",
    exact: true,
    Component: lazy(() => import("./App/Screens/SearchOnMap")),
  },
  {
    path: "/profile",
    exact: true,
    Component: lazy(() => import("./App/Screens/Profile/index")),
  },
  {
    path: "/leaderboard",
    exact: true,
    Component: lazy(() => import("./App/Screens/Leaderboard/index")),
  },
  {
    path: "/village-details/XnofuMnnxp6a5j6MyW85",
    exact: true,
    Component: lazy(() => import("./App/Screens/VillageDetails/index")),
  },
  {
    path: "/village-details/bAELKnYZnkWZBYMck2Pn",
    exact: true,
    Component: lazy(() => import("./App/Screens/VillageDetails/index")),
  },
  {
    path: "/village-details/bmukCSjAfSlJTGAJvJZj",
    exact: true,
    Component: lazy(() => import("./App/Screens/VillageDetails/index")),
  },
  {
    path: "/village-details/:ind",
    exact: true,
    Component: lazy(() => import("./App/Screens/VillageDetails/indexO")),
  },
  {
    path: "/suggest-edit/:key/:name",
    exact: true,
    Component: lazy(() => import("./App/Screens/SuggestEdit/index")),
  },
  {
    path: "/indibadges",
    exact: true,
    Component: lazy(() => import("./App/Screens/indiBadges/index")),
  },
  {
    path: "/kmtravelled",
    exact: true,
    Component: lazy(() => import("./App/Screens/kmTravelled/index")),
  },
  {
    path: "/passport",
    exact: true,
    Component: lazy(() => import("./App/Screens/Passports/index")),
  },
  {
    path: "/achievements",
    exact: true,
    Component: lazy(() => import("./App/Screens/Achievements/index")),
  },
  {
    path: "/upload-image",
    exact: true,
    Component: lazy(() => import("./App/Screens/UploadImage/index")),
  },
];
const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <App />
        <Switch>
          {/* <Route exact path="/login" render={() => <Redirect to="/" />} /> */}
          {routes.map(({ path, Component, exact }) => (
            <Route
              path={path}
              key={path}
              exact={exact}
              render={() => {
                return (
                  <Suspense fallback={null}>
                     
                    <Component />
                  </Suspense>
                );
              }}
            />
          ))}
        </Switch>
       
      </ScrollToTop>
    </ConnectedRouter>
  );
};

export default Router;
