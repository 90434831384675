import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

// const config = {
//   apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
//   authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
//   projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
// };

const firebaseConfig = {
    apiKey: "AIzaSyBXCCXK5_rOqZEAJ1JoQ3Sblxs5dNxlap0",
    authDomain: "mgmd-a8d2c.firebaseapp.com",
    projectId: "mgmd-a8d2c",
    storageBucket: "mgmd-a8d2c.appspot.com",
    messagingSenderId: "337203342194",
    appId: "1:337203342194:web:37d3cd0545047f35dacbeb",
    measurementId: "G-XF5X4Y8XNB"
};

firebase.initializeApp(firebaseConfig);
firebase.database();
firebase.storage();
firebase.firestore();

export default firebase;
