import axios from "axios";

const updateInterestsFromResponse = ({ interests }) => ({
  type: "SET_INTERESTS",
  data: { interests },
});

const updateCurrentTab = ({ currentTab }) => ({
  type: "SET_CURRENT_TAB",
  data: { currentTab },
});

export const getInterests = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios("/client-ApiV1/c-interest-list/");
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });

export const updateSelectedInterests = (interests) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch(updateInterestsFromResponse({ interests }));
    } catch (err) {
      reject(err);
    }
  });

export const setCurrentTab = (currentTab) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch(updateCurrentTab({ currentTab }));
    } catch (err) {
      reject(err);
    }
  });

export const getVillageDetails = (id, lan) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    //console.log(id, lan, "id,lan");
    // try {
    //   const { data } = await axios(
    //     `http://trackhologram.com.s3-website.ap-south-1.amazonaws.com/${id}.json`
    //     // `/client-ApiV1/village-info-detail?id=${id}&language=${lan}`
    //   );
    //   resolve(data);
    // } catch (err) {
    //   reject(err);
    // }
  });

export const getBatchStatus = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios(
        `/client-ApiV1/passport-claim-check?virtual_village=${id}`
      );
      resolve(data);
    } catch (err) {
      reject(err);
    }
  });
