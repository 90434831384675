import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, withRouter, Switch } from "react-router-dom";
import Footer from "./Components/Footer";

class App extends React.Component {
  render() {
    return (
      <Fragment>
        {window.location.pathname === "/" ||
        window.location.pathname === "/signup" ? (
          ""
        ) : (
          <>
            
          </>
        )}
      </Fragment>
    );
  }
}

export default withRouter(App);
