import { all, takeEvery, put, call } from "redux-saga/effects";
import { history } from "../../index";
import { login, currentAccount, logout } from "../../services/users";
import actions from "./actions";

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
      wrong: false,
    },
  });
  const success = yield call(login, email, password);
  if (success) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
  } else {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
        wrong: true,
        login: true,
      },
    });
  }
  // history.push("/home");
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: "settings/GET_COMPANY",
  });
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  const response = yield call(currentAccount);
  if (response) {
    const {
      id,
      email,
      authorized,
      name,
      token,
      role,
      avatar,
      contact,
      interest,
      date_joined,
      last_login,
      bio,
    } = response;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        name,
        email,
        avatar,
        token,
        role,
        contact,
        authorized,
        interest,
        date_joined,
        last_login,
        bio,
      },
    });
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false,
      login: false,
    },
  });
}

export function* LOGOUT() {
  yield call(logout);
  //console.log("saga logout");
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      contact: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
      interest: [],
      date_joined: "",
      last_login: "",
      bio: "",
    },
  });

  yield history.push("/explore");
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
