import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import firebase from "../../../firebase";
import Logo from "../../../App/Assets/img/LogoBlack.svg";
import akamLogoW from "../../../App/Assets/img/akam.svg";
import mocLogoW from "../../../App/Assets/img/mic.svg";
import akamLogo from "../../../App/Assets/img/logo-azadi.svg";
import mocLogo from "../../../App/Assets/img/logo-ministry.svg";
import LogoWhite from "../../../App/Assets/img/LogoWhite.svg";
import MyDesktop from "../../Components/MyComponents/MyDesktop";
import MyMobile from "../../Components/MyComponents/MyMobile";
import DropDownIcon from "../../Assets/img/arrowDown.svg";
import DropDownWhiteIcon from "../../Assets/img/arrowDownWhite.svg";
import axios from "axios";
import i18next from "i18next";

const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
});

class Header extends Component {
  state = {
    drawerOpen: false,
    isDropDown: false,
    fireuser: null,
    loadingLanguage: true,
    languageData: [],
  };

  handleLanguage = () => {
    this.setState({ isDropDown: !this.state.isDropDown });
  };

  componentDidMount = () => {
    this.fetchlanguageData({});
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //console.log("user", user);
        this.setState({ fireuser: user });
      }
    });
    window.addEventListener("storage", (e) => {
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        this.setState({ fireuser: user });
      } catch (e) {}
    });
  };

  fetchlanguageData = (params) => {
    axios
      .get("client-ApiV1/language-list/", { params: { ...params } })
      .then((res) => {
        this.setState({ languageData: res.data, loadingLanguage: false });
      })
      .catch((error) => {
        this.setState({ loadingLanguage: false });
      });
  };
  handleLogout = () => {
    //
    //console.log("signout");
    localStorage.removeItem("user");
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.href = "/";
      });
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {/* DESKTOP HEADER SECTION */}
        <MyDesktop>
          <div
            className={
              "fixed-top " + (this.props.transparent ? "" : "bg-white")
            }
            style={{ height: "auto" }}
          >
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="my-auto py-2" style={{ marginLeft: "1%" }}>
                      <Link to="/explore">
                        <img
                          alt="MGMD Logo"
                          className="my-auto img-fluid"
                          src={this.props.transparent ? LogoWhite : Logo}
                          style={{
                            height: 35,
                          }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="MGMD Logo"
                          className="my-auto img-fluid mx-2"
                          src={this.props.transparent ? mocLogoW : mocLogo}
                          style={{
                            height: 35,
                          }}
                        />
                      </Link>
                      <Link to="/explore">
                        <img
                          alt="MGMD Logo"
                          className="my-auto img-fluid mx-3 cursor-pointer"
                          src={this.props.transparent ? akamLogoW : akamLogo}
                          style={{
                            height: 35,
                          }}
                        />
                      </Link>
                    </div>
                    <div
                      className="row align-items-center my-2 py-2"
                      style={{ marginRight: "1%" }}
                    >
                      {this.state.fireuser?.displayName ? (
                        <div
                          className={`col-6 px-3 th-12 text-capitalize ${
                            this.props.transparent ? "th-white" : "th-black"
                          }`}
                        >
                          {this.state.fireuser?.displayName
                            ? this.state.fireuser?.displayName.split(" ")[0]
                            : "Anonymous"}
                        </div>
                      ) : (
                        <Link className="col" to="/login">
                          <div
                            className={`col-6 px-3 th-12 ${
                              this.props.transparent ? "th-white" : "th-black"
                            }`}
                          >
                            {t("login")}
                          </div>
                        </Link>
                      )}
                      {this.state.fireuser?.displayName ? (
                        <div
                          onClick={(e) => this.handleLogout()}
                          className={`col-6 px-3 py-1 th-br-5 th-12 th-pointer ${
                            this.props.transparent ? "th-white" : "th-black"
                          }`}
                          style={
                            this.props.transparent
                              ? { border: "1px solid #ffffff" }
                              : { border: "1px solid #263238" }
                          }
                        >
                          {t("logout")}
                        </div>
                      ) : (
                        <Link className="col" to="/signup">
                          <div
                            className={`col px-3 py-1 th-br-5 th-12 text-nowrap ${
                              this.props.transparent ? "th-white" : "th-black"
                            }`}
                            style={
                              this.props.transparent
                                ? { border: "1px solid #ffffff" }
                                : { border: "1px solid #263238" }
                            }
                          >
                            {t("signup")}
                          </div>
                        </Link>
                      )}

                      {/* <div className="col">
                        <div
                          className={`th-pointer px-3 th-12 text-uppercase ${this.props.transparent ? "th-white" : "th-black"
                            }`}
                          onClick={() =>
                            this.setState({
                              isDropDown: !this.state.isDropDown,
                            })
                          }
                        >
                          {i18next.language}
                          <span className="pl-1">
                            <img
                              alt="..."
                              src={
                                this.props.transparent
                                  ? DropDownWhiteIcon
                                  : DropDownIcon
                              }
                            />
                          </span>
                        </div>
                        {this.state.isDropDown ? (
                          <div className="th-header-dropdown-content p-2 th-12">
                            {this.state.languageData?.map((item, index) => (
                              <div
                                key={index}
                                className={`p-2 px-3 th-br-3 th-pointer ${i18next.language === item.language_code
                                  ? "th-fw-600"
                                  : null
                                  }`}
                                onClick={() => {
                                  this.handleLanguage();
                                  i18next.changeLanguage(item.language_code);
                                  localStorage.setItem(
                                    "lang",
                                    item.language_code
                                  );
                                }}
                              >
                                {item.language}
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MyDesktop>

        {/* MONILE HEADER SECTION */}
        <MyMobile>
          <div
            className={
              "fixed-top " + (this.props.transparent ? "" : "bg-white")
            }
          >
            <div className="container py-1">
              <div className="row align-items-center">
                <div className="col-6 py-2">
                  <div className="my-auto text-nowrap">
                    <Link to="/explore">
                      <img
                        alt="MGMD Logo"
                        className="my-auto img-fluid"
                        src={this.props.transparent ? LogoWhite : Logo}
                        style={{
                          height: "20px",
                        }}
                      />
                    </Link>
                    <Link to="/explore">
                      <img
                        alt="MGMD Logo"
                        className="my-auto img-fluid mx-2"
                        src={this.props.transparent ? mocLogoW : mocLogo}
                        style={{
                          height: "20px",
                        }}
                      />
                    </Link>
                    <Link to="/explore">
                      <img
                        alt="MGMD Logo"
                        className="my-auto img-fluid mx-2"
                        src={this.props.transparent ? akamLogoW : akamLogo}
                        style={{
                          height: "20px",
                        }}
                      />
                    </Link>
                  </div>
                </div>

                {this.state.fireuser?.displayName ? (
                  <div
                    className={`col-3 px-3 d-flex justify-content-end  th-12 text-capitalize ${
                      this.props.transparent ? "th-white" : "th-black"
                    }`}
                  >
                    {this.state.fireuser?.displayName
                      ? this.state.fireuser?.displayName.split(" ")[0]
                      : "Anonymous"}
                  </div>
                ) : (
                  <Link className="col-3" to="/login">
                    <div
                      className={`col   d-flex justify-content-end th-12 ${
                        this.props.transparent ? "th-white" : "th-black"
                      }`}
                    >
                      {t("login")}
                    </div>
                  </Link>
                )}
                {this.state.fireuser?.displayName ? (
                  <div
                    onClick={(e) => this.handleLogout()}
                    className={`col-2 d-flex justify-content-center px-3 py-1 th-br-5 th-12 th-pointer ${
                      this.props.transparent ? "th-white" : "th-black"
                    }`}
                    style={
                      this.props.transparent
                        ? { border: "1px solid #ffffff" }
                        : { border: "1px solid #263238" }
                    }
                  >
                    {t("logout")}
                  </div>
                ) : (
                  <Link className="col-3" to="/signup">
                    <div
                      className={`col px-3 py-1  d-flex justify-content-center  th-br-5 th-12 text-nowrap ${
                        this.props.transparent ? "th-white" : "th-black"
                      }`}
                      style={
                        this.props.transparent
                          ? { border: "1px solid #ffffff" }
                          : { border: "1px solid #263238" }
                      }
                    >
                      {t("signup")}
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </MyMobile>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Header)));
