import React, { Component, Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
const mapStateToProps = ({ dispatch, user }) => ({
  dispatch,
  user,
});
class ScrollToTopRoute extends Component {
  componentDidUpdate(prevProps) {
    // //console.log(this.props.location.pathname);
    // if (
    //   this.props.path === this.props.location.pathname ||
    //   this.props.location.pathname !== prevProps.location.pathname
    // ) {
    window.scrollTo(0, 0);
    // }
  }

  render() {
    const { user } = this.props;
    const { component: Component, ...rest } = this.props;
    const isUserAuthorized = user.authorized;
    const isUserLoading = user.loading;
    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized) {
        return (
          <div className="th-ht-100 d-flex justify-content-center align-items-center">
            <div className=" spinner-grow th-primary text" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
      } else {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      }
    };
    return <Fragment>{BootstrappedLayout()}</Fragment>;
  }
}

export default withRouter(connect(mapStateToProps)(ScrollToTopRoute));
