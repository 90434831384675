/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "../../../firebase";
import LeaderboardIcon from "../../Assets/img/sidebar/leaderboard.svg";
import DiscoverIcon from "../../Assets/img/sidebar/explore.svg";
import DashboardIcon from "../../Assets/img/sidebar/dashboard.svg";
import GalleryIcon from "../../Assets/img/sidebar/photo-library-colored.svg";
import ProfileIcon from "../../Assets/img/sidebar/user.svg";
import DiscoverIconColored from "../../Assets/img/sidebar/explore-colored.svg";
import DashboardIconColored from "../../Assets/img/sidebar/dashboard-colored.svg";
import GalleryIconColored from "../../Assets/img/sidebar/photo_library.svg";
import ProfileIconColored from "../../Assets/img/sidebar/user-colored.svg";
import LeaderboardIconColored from "../../Assets/img/sidebar/leaderboard-colored.svg";
import { setCurrentTab } from "../../../redux/app/actions";
import Vectormapsearch from "../../Assets/img/sidebar/Vectormapsearch.svg";
import Vectortintedmapsearch from "../../Assets/img/sidebar/VectortintedMapSearch.svg";
const mapStateToProps = ({ user, dispatch, settings, app }) => ({
  dispatch,
  user,
  settings,
  app,
});

const actionsMapping = (dispatch) => ({
  setCurrentTab: (currentTab) => dispatch(setCurrentTab(currentTab)),
});

const WinWidth = window.innerWidth;

const FooterData = [
  {
    id: 0,
    text: "Explore",
    icon: DiscoverIcon,
    iconActive: DiscoverIconColored,
    to: "/explore",
  },
  {
    id: 1,
    text: "Leaderboard",
    icon: LeaderboardIcon,
    iconActive: LeaderboardIconColored,
    to: "/leaderboard",
  },
  {
    id: 2,
    text: "Dashboard",
    icon: DashboardIcon,
    iconActive: DashboardIconColored,
    to: "/home",
  },
  // {
  //   id: 3,
  //   text: "Gallery",
  //   icon: GalleryIcon,
  //   iconActive: GalleryIconColored,
  //   to: "/gallery",
  // },

  {
    id: 4,
    text: "Profile",
    icon: ProfileIcon,
    iconActive: ProfileIconColored,
    to: "/profile",
  },
  {id:5,
    text: "Search On Map",
    icon: Vectormapsearch,
    iconActive: Vectortintedmapsearch,
    to: "/searchonmap",}
];

class Footer extends Component {
  handleIcon = (e) => {
    this.props.setCurrentTab(e);
  };
  render() {
    const currentSideTab = this.props.app?.currentTab ?? 0;
    return (
      // <Router>
      <React.Fragment>
        <div className="w-100 fixed-bottom container-fluid th-footer">
          <div className="row py-2">
            {FooterData.map((item, index) => (
              <div
                className="col"
                key={index}
                // style={{ width: WinWidth / FooterData.length,  }}
              >
                <Link
                  to={
                    item.id === 4
                      ? localStorage.getItem('user')
                        ? "/profile"
                        : "/login"
                      : item.id === 2
                      ? localStorage.getItem('user')
                        ? "/home"
                        : "/login"
                      : item.to
                  }
                >
                  <div
                    className="th-pointer text-center"
                    onClick={() => this.handleIcon(item.id)}
                  >
                    <img
                      className="img-fluid"
                      src={
                        currentSideTab === item.id ? item.iconActive : item.icon
                      }
                      height="25px"
                    />
                    <div
                      className={
                        "th-12  " +
                        (currentSideTab === item.id
                          ? " th-primary"
                          : "th-grey-3")
                      }
                    >
                      {item.text}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
      // </Router>
    );
  }
}

export default connect(mapStateToProps, actionsMapping)(Footer);
