import actions from "./actions";
// import store from "store";
import firebase from "../../firebase";
let user = localStorage.getItem('user');
const initialState = {
  id: user ? user.uid : 10001,
  name: user ? user.displayName : "",
  role: {},
  email: user ? user.email : "",
  avatar: user ? user.photoURL : "",
  token: "",
  authorized: user ? true : false,
  loading: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
