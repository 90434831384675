import axios from "axios";
import setAuthToken from "../global/setAuthToken";
import store from "store";
import firebase from "../firebase";
export async function login(email1, password1) {
  const value = { email: email1, password: password1 };
  return axios
    .post(`/token-auth/`, value)
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        setAuthToken(response.data.token);
        store.set("user", response.data.user.id);
        return response.data;
      } else if (response.status !== 200) {
        return false;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

export async function currentAccount() {
  let authorized1 = true;

  let user = firebase.auth().currentUser;

  return axios
    .get(`/apiV1/current-user/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      authorized1 = true;
      setAuthToken(localStorage.getItem("token"));
      const { data } = response;
      return {
        id: data.id,
        authorized: authorized1,
        email: data.email,
        role: data.roles,
        email: data.email,
        contact: data.contact,
        name: data.name,
        token: localStorage.getItem("token"),
        avatar: data.avatar,
        interest: data.interest,
        date_joined: data.date_joined,
        last_login: data.last_login,
        bio: data.bio,
      };
    })
    .catch((error) => { });
}

export async function logout() {
  window.localStorage.clear();
  //console.log(localStorage, "localstorage");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.reload();
}
