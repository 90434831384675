import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Router from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App/Assets/css/general.css";
import "./App/Assets/css/style.scss";
import CacheBuster from "./CacheBuster";
import reducers from "./redux/reducers";
import sagas from "./redux/sagas";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { persistStore } from "redux-persist";

import Axios from "axios";
import { createBrowserHistory } from "history";
import "./services/i18n";

Axios.defaults.baseURL = "https://api.amritbharat.in/";
Axios.defaults.headers.common.Authorization =
  localStorage.getItem("token") !== null
    ? localStorage.getItem("token").length > 0
      ? `JWT ${localStorage.getItem("token")}`
      : ``
    : ``;
Axios.defaults.headers.post["Content-Type"] = "application/json";
// middlewared
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
const store = createStore(reducers, compose(applyMiddleware(...middlewares)));
const persister = persistStore(store);

sagaMiddleware.run(sagas);

ReactDOM.render(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }
      return (
        <Provider store={store}>
          <Router history={history} />
        </Provider>
      );
    }}
  </CacheBuster>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { store, history, persister };
