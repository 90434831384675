
const initialState = {
    interests: [],
    currentTab: 0,
}

const appReducer = (state = initialState, action) => {
    if (action.type === 'SET_INTERESTS') {
        const { interests } = action.data
        return {
            ...state,
            interests,
        }
    }
    if (action.type === 'SET_CURRENT_TAB') {
        const { currentTab } = action.data
        return {
            ...state,
            currentTab,
        }
    }
    if (action.type === 'APP_LOGOUT') {
        return {
            ...state,
            ...initialState,
            currentTab: 2,
        }
    }
    return state
}

export default appReducer